import React, { 
  createContext, 
  useContext,
  useState,  
  useEffect,
  createElement,
} from 'react';
import { useLogin } from '../cookies';
import { refreshToken} from '../api/token';
const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

const NavigationProvider = (props) => {
  const [navigationData, setNavigationData] = useState({});

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component) {
  return function (props) {
    const { path } = props.match;
    const { navigationData, setNavigationData } = useNavigation();
    const {token, setToken} = useLogin();

    useEffect(() => {
      const navigation = async ()=>{
        const loginPathRegex = /(?:\/login)/; 
        // We don't refresh the token if user is not logged
        // token in cookies may be expired
        if(!loginPathRegex.test(path) && token && token !== undefined && token !== null && path !== navigationData.currentPath){
          // Refresh token
          const newToken = await refreshToken(token)

          // Store token in cookies
          setToken(newToken)
          
        }
        setNavigationData({ currentPath: path});
      }
      navigation()
      
    }, [path, setNavigationData, token, navigationData.currentPath, setToken]);

    return createElement(Component, props);
  }
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}