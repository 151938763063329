
import {useRef, useState } from "react";
import { updateAllowedChaptersForCohort } from "../../api/cohort";


import { DataGrid } from "devextreme-react";

import { Column, Selection } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { useLocation } from "react-router";
import { getAvatarColor } from "../../utils/utils";
import {Button} from "../../components";
import "./cohort.scss";
import { useLogin } from "../../cookies";
  
  export const  ChapterGrid = ({ data, fetchCohortData}) => {
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [loading, setLoading] = useState(false);
    const chaptersDataRef = useRef(null);
    const { pathname } = useLocation();
    const {token} = useLogin()

    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const chaptersColumn = [{ key: "title", label: "Title" }];
    const isSelectedChapters = ["selected", "=", true];

    const handleUpdate = async () => {
      setLoading(true);

      const allowedChapters = selectedChapters.map(chapter => {
        return chapter.id.toString()
      })

      const res = await updateAllowedChaptersForCohort(token, {
        cohortId: cohortId,
        courseSlug: data.slug,
        allowedChapters:
          allowedChapters
      }, data?.perimeter )

      if (res.isOk) {
        fetchCohortData()
          notify("Cohort updated", "info", 2000);
        }
      else {
        notify(res.message, "error", 2000);
      }
      setLoading(false);
    };

    const handleChaptersChange = async (e) => {
      const chapterSelection = await chaptersDataRef.current.instance.getSelectedRowsData();
      setSelectedChapters(chapterSelection);
    };

    const renderCourseCell = (props) => {
      const { data, value } = props;

      const renderAvatar = () => {
        return props.data.imageUrl ? (
          <div
            style={{
              background: `url(${data.imageUrl}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        ) : (
          <div
            style={{ background: getAvatarColor(value) }}
            className={"text-image"}
          >
            {value.substring(0, 2)}
          </div>
        );
      };

      return (
        <div className={"userinfo"}>
          <div className={"image-container"}>{renderAvatar()}</div>
          <div className={"username"}>{props.value}</div>
        </div>
      );
    };

    return (
      <div>
        <DataGrid
          id="gridContainer"
          ref={chaptersDataRef}
          dataSource={data.chapters}
          showColumnLines={true}
          showRowLines={false}
          hoverStateEnabled={true}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="slug"
          defaultSelectionFilter={isSelectedChapters}
          onSelectionChanged={handleChaptersChange}
        >
          <Selection
            mode="multiple"
            selectAllMode={true}
            showCheckBoxesMode={"always"}
            deferred={true}
          />
          {chaptersColumn.map((column) => (
            <Column
              key={column.key}
              dataField={column.key}
              caption={column.label}
              dataType={column.dataType}
              cellRender={renderCourseCell}
            />
          ))}
        </DataGrid>
        <Button
          text="Update"
          loading={loading}
          onClick={handleUpdate}
        />
      </div>
    );


  };