import { default as React, useEffect, useState } from "react";
import { getMetabaseSignedUrl } from "../../api/metabase";
import { Spinner } from "../../components";
import { useLocation } from "react-router";
import { useLogin } from "../../cookies";



const ReportingDashboard = () => {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const key = pathArray[3]
  const [metabaseUrl, setMetabaseUrl] = useState();
  const {token} = useLogin()

  useEffect(() => {
    if(key && token){
      getMetabaseUrl(token, key);
    }
  }, [key, token, metabaseUrl]);

  const getMetabaseUrl = async (token, slug) => { 
    const url = await getMetabaseSignedUrl(token, slug);
    if(url) {
      setMetabaseUrl(url.data)
    }
  }
  
return (
  metabaseUrl ? 
    <>
      <div className={"content-block dx-card responsive-paddings"}>
      <iframe 
        id="myFrame"
        title="Reporting Dashboard"
        src={metabaseUrl} 
        width="100%"
        height={1000}
       >

      </iframe>
      </div>
    </> :
    <>
    <Spinner/>
    </>
  );
};

export default ReportingDashboard;
