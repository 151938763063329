import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { signIn } from '../../api/auth'

export const loginThunk = createAsyncThunk(
  'user/login',
  async ({programId, login, password}, thunkAPI) => {
    try {
      const response = await signIn(programId, login, password)
      if(!response.isOk){
        return thunkAPI.rejectWithValue(response)
      }

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue({
        isOk: false,
        message: error.message || 'Erreur inconnue'
      })
    }
  }
)


const init = {
  user : {
    programId : null, 
    userId : null, 
    userRoles : [],
    firstName : null, 
    lastName : null,
  }, 
  loading : false,
  success : {
    isOk : false,
    message : null
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState: init,
  reducers: {
    addUser(state, action) {
      return action.payload
    }, 
    logout(state) {
      return init
    },

  }, 
  // Fetch Login
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
      state.success.isOk = action.payload.isOk
      state.success.message = null
    })
    builder.addCase(loginThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(loginThunk.rejected, (state, action) => {
      return {
        ...init,
        success : {
          isOk : false,
          message : action.payload.message
        }
      }
    })
  },

})

export const { logout, addUser } = userSlice.actions

export const user = (state) => state.user.user
export const userLoading = (state) => state.user.loading
export const userSuccess = (state) => state.user.success
export const userName = (state) => {return {firstName : state.user.user.firstName, lastName : state.user.user.lastName}}


export default userSlice.reducer


