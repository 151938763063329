import { Tabs } from "devextreme-react";
import { default as React, useMemo } from "react";
import { useLocation } from "react-router";
import "./cohort.scss";
import CoursesDetails from "./coursesDetails";
import CohortData from './cohortData'
import Sessions from "../sessions/sessions";
import { useSelector } from "react-redux";
import { selectedPerimetersId } from "../../utils/redux/perimeterSlice";
import {
  fetchCohortById,
} from "../../api/cohort";
import { getAllCourses } from "../../api/courses";
import { fetchFarmers } from "../../api/farmers";
import notify from "devextreme/ui/notify";
import { useProgram } from '../../programs'
import { useHistory } from "react-router";
import { useLogin } from "../../cookies";


const Cohort = () => {
  const { pathname, state } = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const isEditMode = pathname.split("/").includes("edit");

  const title = isEditMode ? "Edit cohort" : "Create cohort";

  const wasInSession = state?.back === "session" ? true : false;

  const selectedPerimeters =  useSelector(selectedPerimetersId);
    const {token} = useLogin()

  const [loadingCohort, setLoadingCohort] = React.useState(true);
  const [loadingCourse, setLoadingCourse] = React.useState(true);
  const [loadingParticipants, setLoadingParticipants] = React.useState(true);
  const [allCourses, setAllCourses] = React.useState([]);
  const [farmersData, setFarmersData] = React.useState([]);
  const [stampSelectedCourses, setStampSelectedCourses] = React.useState([]);
  const [selectedInstructors, setSelectedInstructors] = React.useState({
      firstName: "",
      lastName: "",
    });
  const [selectedCourses, setSelectedCourses] = React.useState([]);
  const [selectedFarmers, setSelectedFarmers] = React.useState([]);
    
  
  const program = useProgram()
  const history = useHistory();
  const [cohortInfo, setCohortInfo] = React.useState({
      name: "",
      gpsPoint : null
    });

    const isMounted = React.useRef(false);
    // Prevent rendering
    const prevPerimeters =  React.useRef(selectedPerimeters);

      // REQUESTS RESPONSES
      const fetchAllFarmers = React.useCallback(async (usersSelected = [], perimeter = selectedPerimeters.join(",")) => {
        if(selectedPerimeters.length === 0 && !isEditMode) {
          return setFarmersData([])
        }else{
          // Use state of farmers sent by attendance list
          let farmersDataToset;
          if(state?.farmersData?.length > 0){
            farmersDataToset = state.farmersData
          }else{
            const res = await fetchFarmers(token, perimeter);
            if (res.isOk) {
              farmersDataToset = res.data
            } else {
              notify("Failed to fetch farmers", "error", 2000);
              return;
            }  
          }
            const filteredFarmers = farmersDataToset?.map((farmer) => { 
              return {
                ...farmer,
                userId: farmer.userId.toString(),
                fullname: `${farmer.firstName} ${farmer.lastName}`,
              };
            }).filter((farmer) => {
              const selectedFarmersId = usersSelected.map(
                (selected) => selected.userId
              );
              return !selectedFarmersId.includes(farmer.userId);
            });
            setFarmersData(filteredFarmers);
  
        }
    
      }, [token, selectedPerimeters, isEditMode, state?.farmersData]);
    
    
      const fetchCohort = React.useCallback(async (coursesData = []) => {
        const splittedPath = pathname.split("/");
        const cohortId = splittedPath[splittedPath.length - 1];
        const res = await fetchCohortById(token, cohortId, selectedPerimeters.join(","));
        if (res.isOk) {
          const {
            name,
            instructorDetails,
            instructor,
            gpsPoint, 
            participants,
            courses,
            perimeter
            } = res.data;
          setCohortInfo({
            name: name,
            gpsPoint : gpsPoint,
            perimeters: perimeter
          });
          setSelectedInstructors({ ...instructorDetails, userId: instructor });
          const coursePreSelected = coursesData.filter((course) => {
            const result = courses.map((course) => course.slug).includes(course.slug);
            return result 
          });
    
          const courseObject = coursePreSelected.map((course) => {
            const correspondingCourse = courses.find(
              (item) => item.slug === course.slug
            );
            if (correspondingCourse.allowedChapters) {
              return {
                ...course,
                completed: correspondingCourse.completed,
                started: correspondingCourse.started,
                sessionHours: correspondingCourse.sessionHours,
                allowedChapters: correspondingCourse.allowedChapters,
              };
            } else return {
              ...course, allowedChapters: [],
              completed: correspondingCourse.completed,
              started: correspondingCourse.started,
              sessionHours: correspondingCourse.sessionHours
            };
          });
          setSelectedCourses(courseObject);
          setStampSelectedCourses(courseObject);
          
          const participantToSet = participants.map((farmer) => {
            return {
              ...farmer,
              userId: farmer.participant.toString(),
              fullname: `${farmer.firstName} ${farmer.lastName}`,
              isAmbassador: farmer.isAmbassador !== 0
            };
          });
          return {participants : participantToSet, perimeter : res.data.perimeter};
        } else {
          if(res.message.includes("404")){
            notify("Cohort not found", "error", 2000)
            history.push(`/${program.name}/cohorts`); 
          }else{
            notify("Failed to fetch cohort", "error", 2000)
          }
        };
      }, [pathname, token, selectedPerimeters, history, program]);


      const tabs = useMemo(() => {
        return [
          {
            id: 0,
            text: "Cohort Details",
            content: () => 
              <CohortData
                farmersData={farmersData}
                loadingCohort={loadingCohort}
                loadingCourse={loadingCourse}
                loadingParticipants={loadingParticipants}
                allCourses={allCourses}
                cohortInfo={cohortInfo}
                setCohortInfo={setCohortInfo}
                stampSelectedCourses={stampSelectedCourses}
                setStampSelectedCourses={setStampSelectedCourses}
                selectedInstructors={selectedInstructors}
                setSelectedInstructors={setSelectedInstructors}
                selectedCourses={selectedCourses}
                setSelectedCourses={setSelectedCourses}
                selectedFarmers={selectedFarmers}
                setSelectedFarmers={setSelectedFarmers}
                isEditMode={isEditMode}
              />
            ,
          },
          {
            id: 1,
            text: "Courses Details",
            content: (props) => <CoursesDetails courses={selectedCourses} name={cohortInfo.name} fetchCohortData={fetchCohort} />,
          },
          {
            id: 2,
            text: "Sessions",
            content: () => (
            <Sessions
              farmersData={farmersData}
            />
          ),
          }
        ];
      }, [fetchCohort, allCourses,isEditMode, cohortInfo, farmersData, loadingCohort, loadingCourse, loadingParticipants, selectedCourses, selectedFarmers, selectedInstructors, stampSelectedCourses]); 
      
      const [currentTab, setCurrentTab] = React.useState(tabs[0]);


      const fetchCourses = React.useCallback(async () => {
        const res = await getAllCourses(token, program.id, selectedPerimeters.join(","));
        if (res.isOk) {
          return res.data
          
        } else notify("Failed to fetch courses", "error", 2000);
      }, [token, program.id, selectedPerimeters]);

    // fetch data used to have async calls 
    const fetchData = React.useCallback(async () => {
    prevPerimeters.current = selectedPerimeters;
      if(selectedPerimeters.length === 0 && !isEditMode){
        setAllCourses([]);
        setFarmersData([]);
        setLoadingCourse(false);
        setLoadingCohort(false);
        setLoadingParticipants(false);
      }else{
        const courses = await fetchCourses();
        if(isEditMode)  {
          const {participants, perimeter} = await fetchCohort(courses);
          await fetchAllFarmers(participants, perimeter);
          setSelectedFarmers(participants);


        }else{
          await fetchAllFarmers()
        }
        setAllCourses(courses);
        setLoadingCourse(false);
        setLoadingCohort(false);
        setLoadingParticipants(false);
      }
  
    }, [isEditMode, fetchCohort,fetchCourses, fetchAllFarmers, selectedPerimeters]);

  
    React.useEffect(() => {
      const perimetersChanged = JSON.stringify(prevPerimeters.current) !== JSON.stringify(selectedPerimeters)

      //Prevent re-rendering
      if (!isMounted.current) {
        fetchData();
        // Stop rerendering
        isMounted.current = true;
      } else if (perimetersChanged && !isEditMode) {
        prevPerimeters.current = selectedPerimeters;
        fetchData();
      }
    }, [fetchData, selectedPerimeters, isEditMode]);

  React.useEffect(() => {
    if (wasInSession) {
      setSelectedIndex(2);
      setCurrentTab(tabs[2]);
    }
  }, [wasInSession, tabs]);
  

  const handleTabChange = (args) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
      setCurrentTab(tabs[args.value]);
    }
  };

  return (
    tabs.length > 0 &&
    <>
      <h2 className={"content-block"}>{title}</h2>
      <div className={"content-block dx-card responsive-paddings"}>
        <Tabs
          dataSource={tabs}
          selectedIndex={selectedIndex >= 0 ? selectedIndex : 0}
          onOptionChanged={handleTabChange}
          scrollingEnabled
          disabled={!isEditMode}
        />
        <div className={"dx-card responsive-paddings"}>
          {currentTab?.content?.({
            isEditMode,
            isEditing: true,
            onTabChange: handleTabChange,
          })}
        </div>
      </div>
    </>
  );
};

export default Cohort;
