import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../utils/redux/userSlice';
import { clearPerimeters } from '../utils/redux/perimeterSlice';
import { useLogin } from '../cookies';

export default function Logout() {
  const dispatch =  useDispatch()
  const {removeToken} = useLogin()

  useEffect(() => {
    dispatch(logout())
    dispatch(clearPerimeters())
    removeToken()

  },
    [dispatch, removeToken]);

  return (
    <></>
  );
}
