import React, { useCallback, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { appInfo } from "./app-info";
import { appRoutes, getWelcomePath } from './app-routes'
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { useProgram } from './programs'
import { useSelector } from 'react-redux';
import { getUserPerimetersThunk, perimeters } from './utils/redux/perimeterSlice';
import { useDispatch } from 'react-redux';
import { useLogin } from './cookies';

export default function Content() {
  const dispatch = useDispatch()
  const program = useProgram()
  const allowedPerimeters = useSelector(perimeters)
  const {token} = useLogin()

  const fetchPerimeters = useCallback(async() => {
    dispatch(getUserPerimetersThunk(token))
  }, [token, dispatch])

  // Fetch perimeters allowed
  useEffect(() => {
    if(allowedPerimeters?.length === 0 && token) {
      fetchPerimeters()
    }
  }, [allowedPerimeters, fetchPerimeters, token]);

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        { appRoutes.map(({ path, component }) => (
          <Route exact key={path} path={path(program.name)} component={component} />
        ))}
        <Redirect to={getWelcomePath(program.name)} />
      </Switch>
    </SideNavBarLayout>
  );
}
