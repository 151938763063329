import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import './themes/dx.material.sutti.css'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { NavigationProvider } from './contexts/navigation';
import { MultiPrograms, useProgram } from './programs'
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { useScreenSizeClass } from './utils/media-query';
import './dx-styles.scss';
import { useSelector } from 'react-redux';
import { user as selectUser } from './utils/redux/userSlice';
import { Logout } from './pages';

function App() {
  const program = useProgram()
  const user = useSelector(selectUser)

  if (user?.programId != null) {
    if(user.programId === program.id){
      return <Content />;
    } else {
      return <Logout />
    }
  }

  return <UnauthenticatedContent />;
}

export default function MainScene() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <NavigationProvider>
        <div className={`app ${screenSizeClass}`}>
          <MultiPrograms>
            <App />
          </MultiPrograms>
        </div>
      </NavigationProvider>
    </Router>
  );
}
