import { useEffect, useState, useRef, useCallback } from "react";
import { useProgram } from '../../programs';
import { useHistory } from "react-router";
import { getCohortCreatePath, getCohortEditPath } from '../../app-routes';
import { 
  getAllCohorts, 
  sendSmsToCohort,
  sendNotificationToCohort 
} from "../../api/cohort";
import { LoadIndicator } from "devextreme-react";
import DataGrid, {
  Column,
  Editing,
  FilterRow,
  SearchPanel,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { MessagePopup, Button } from "../../components";
import notify from "devextreme/ui/notify";
import { notificationCohort } from "../../utils/utils";
import { useScreenSize } from "../../utils/media-query";
import { useSelector } from "react-redux";
import { selectedPerimetersId } from "../../utils/redux/perimeterSlice";
import { useLogin } from "../../cookies";

const columns = [
  { key: "name", label: "Name" },
  { key: "usualLocation", label: "Location" },
  { key: "instructorDisplayName", label: "Instructor" },
  { key: "participantCount", label: "Number of participant" },
];

const Cohorts = () => {
  const program = useProgram();
  const history = useHistory();
  const {token} = useLogin()
  const [loading, setLoading] = useState(true);
  const selectedPerimeters =  useSelector(selectedPerimetersId)

  const [cohortsDataSet, setCohortDataSet] = useState([]);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [message, setMessage] = useState("");
  const [cohortIdToMessage, setCohortIdToMessage] = useState("");
  const [cohortName, setCohortName] = useState("");
  const [options, setOptions] = useState({
    method : {
      label : "SMS or Notification ?",
      value : "Notification",
      messagePreview : "Description :",
      dataValue : "method"
    }
  })
  const { isSmall, isXSmall, isLarge, isXLarge, isMedium} = useScreenSize();



  const datagridRef = useRef(null);

  const prevPerimeters =  useRef(selectedPerimeters);
  const isMounted =  useRef(false);

  const renderActionButton = () => {
    return(
      <Column type="buttons" width={110}>
      <GridButton
        hint="Send message"
        icon="email"
        visible={true}
        onClick={(e) => handleSendMessageClick(e)}
      />
      <GridButton name="edit" />

      <GridButton
      hint="Edit in new tab"
      icon="plus"
      visible={true}
      onClick={(e) => openInNewTab(`edit-cohort/edit/${e.row.data.id}`)}
      />
      <GridButton name="delete" />
    </Column>
    )
  }


  const fetchAllCohorts = useCallback(async () => {
    const res = await getAllCohorts(token, selectedPerimeters.join(","));

    if (res.isOk) {
      const myFilteredRes = res.data.filter(el => el.id !== '9999');
      setCohortDataSet(myFilteredRes);
      prevPerimeters.current = selectedPerimeters
    } else {
      notify(res.message, 'error', 2000);
    }
    setLoading(false);
  }, [token, selectedPerimeters]);

  useEffect(() => {
    const perimetersChanged = JSON.stringify(prevPerimeters.current) !== JSON.stringify(selectedPerimeters)
    if(selectedPerimeters.length === 0) {
      setCohortDataSet([]);
      setLoading(false);
      prevPerimeters.current = [];
    }
    else if (!isMounted.current) {
      fetchAllCohorts();
      // Stop rerendering
      isMounted.current = true;
    }
    else if (perimetersChanged) {
      fetchAllCohorts();
    }
  }, [fetchAllCohorts, selectedPerimeters, prevPerimeters]);

  const handleCreateClick = () => {
    history.push(getCohortCreatePath(program.name))
  }

  const handleEditClick = (props) => {
    history.push(getCohortEditPath(program.name, props.data.id))
  }

  const openInNewTab = (url) => {
    const perimeterParams = selectedPerimeters.length > 0 ? `?perimeters=${selectedPerimeters}` : null
    const newUrl = `${url}${perimeterParams ?? ''}`
    const newWindow = window.open(newUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleSendNotification = async () => {
    const response = await sendNotificationToCohort(token, {
      message,
      cohortId: cohortIdToMessage.toString(),
    })
    if (response.isOk) {
      notify(`Notification sent to cohort ${cohortName} successfully`, "success", 5000);
    } else {
      notify(`Failed to send notification`, "error", 2000);
    }
    handleCloseMessagePopup();
  }

  const handleSendSms = async () => {
    const response = await sendSmsToCohort(token, {
      messageContent: message,
      cohortId: cohortIdToMessage.toString(),
    });
    if (response.isOk) {
      if(response.data.includes("Message failed to send to")){
        notify(response.data, "warning", 5000);
      }else{
        notify(response.data, "success", 2000);
      }
    } else {
      notify(`Failed to send sms`, "error", 2000);
    }
    handleCloseMessagePopup();
  };

  const handleCloseMessagePopup = () => {
    setShowMessagePopup(false);
    setMessage('');
  };

  const handleSendMessageClick = (e) => {
    setCohortIdToMessage(e.row.data.id);
    setCohortName(e.row.data.name)
    setShowMessagePopup(true);
  };

  const handleMessageChange = (name, value) => {
    // If second parameter is undefined, it's the message
    if(name === "message"){
      setMessage(value)

    }else{
      setOptions(prevOptions => ({
        ...prevOptions,
        [name]: {
          ...prevOptions[name],
          value: value
        }
      }));
    }

  };

  const handleSendMessage = () => {
    if(options.method.value === 'SMS') {
      handleSendSms()
    } else if(options.method.value === 'Notification') {
      handleSendNotification()
    }
  }

  return (
    <>
      <h2 className={"content-block"}>Cohorts</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Button text="Create a cohort" onClick={handleCreateClick} />

          {loading ? (
            <div>
              <LoadIndicator width={"24px"} height={"24px"} visible={true} />
            </div>
          ) : (
            <DataGrid
              id="gridContainer"
              dataSource={cohortsDataSet}
              ref={datagridRef}
              onEditingStart={handleEditClick}
              defaultFocusedRowIndex={0}
              showBorders={true}
              columnAutoWidth={true}
              showColumnLines={true}
              showRowLines={false}
            >
              <Editing allowUpdating={true} allowDeleting={false} />
              <FilterRow visible={true} />
              <SearchPanel visible={true} width={240} placeholder="Search..." />
              {(isSmall || isXSmall) && renderActionButton()}
              {
                columns.map((column) => (
                  <Column
                    key={column.key}
                    dataField={column.key}
                    caption={column.label}
                    dataType={column.dataType}
                  />
                ))
              }
              {(isMedium || isLarge || isXLarge) && renderActionButton()} 
            </DataGrid>
          )}
        </div>
          <MessagePopup
          loading={loading}
          open={showMessagePopup}
          message={message}
          options={options}
          onClose={handleCloseMessagePopup}
          onMessageChange={handleMessageChange}
          onSendSms={handleSendMessage}
          type={notificationCohort}
          previewMessage={message.length > 0 ? message : ""}
        />
      </div>
    </>
  );
};

export default Cohorts;