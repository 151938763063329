import { getService, postService, putService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

export async function getAllCohorts(token, perimeters) {
  const url = perimeters ?  `${baseUrl}/bo/cohort/getAllCohorts?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/cohort/getAllCohorts`;
  const fetchedInstructors = await getService(token, url);
  return fetchedInstructors;
}

export async function fetchCohortById(token, cohortId, perimeters) {
  const url = perimeters ? `${baseUrl}/bo/cohort/getCohortById?cohort-id=${cohortId}&perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/cohort/getCohortById?cohort-id=${cohortId}`;
  const fetchedCohort = await getService(token, url);
  return fetchedCohort;
}

export async function createCohort(token, values, perimeters) {
  const url = perimeters ? `${baseUrl}/bo/cohort/postCohort?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/cohort/postCohort`;
  const res = await postService(token, url, values);
  return res;
}

export async function updateCohort(token, values, perimeters) {
  const url = perimeters ? `${baseUrl}/bo/cohort/putCohort?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/cohort/putCohort`;
  const res = await putService(token, url, values);
  return res;
}

export async function sendSmsToCohort(token, values) {
  const url = `${baseUrl}/bo/sms/sendSmsToCohort`
  const res = await postService(token, url, values)
  return res
}

export async function sendNotificationToCohort(token, values) {
  const url = `${baseUrl}/bo/notification/sendNotificationToCohort`
  const res = await postService(token, url, values)
  return res
}

export async function updateAllowedChaptersForCohort(token, values, perimeters) {
  const url = perimeters ? `${baseUrl}/bo/cohort/putChaptersOnCourseByCohort?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/cohort/putChaptersOnCourseByCohort`;
  const res = await putService(token, url, values)
  return res
}

export async function fetchCohortLocation(token, id) {
  const url = `${baseUrl}/bo/cohort/cohortLocation?cohortId=${id}`;
  const fetchedCohort = await getService(token, url);
  return fetchedCohort;
}