import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserPerimeters } from '../../api/auth'

export const getUserPerimetersThunk = createAsyncThunk(
  'perimeter/getUserPerimeters',
  async (token, thunkAPI) => {
    const response = await getUserPerimeters(token)
    return response.data
  },
)

const init = {
  perimeters :[], 
  selectedPerimeters :[], 
  loading : 'success'
}

const allowedRoles = ['INSTRUCTOR', 'LOCAL_BUYER', 'REPORTER']
const perimeterSlice = createSlice({
  name: 'perimeter',
  initialState: init,
  reducers: {
    updateSelectedPerimeter(state, action) {
      state.selectedPerimeters = action.payload
    }, 
    clearPerimeters(state){
      return init
    }
  }, 
  // Fetch perimeters allowed
  extraReducers: (builder) => {
    builder.addCase(getUserPerimetersThunk.fulfilled, (state, action) => {
      // Filter perimeters that contains not only participant role
      state.perimeters = action.payload?.filter((perimeter) => perimeter.roles.some((role) => allowedRoles.includes(role)))
      state.loading = 'success'
    })
    builder.addCase(getUserPerimetersThunk.pending, (state) => {
      state.loading = 'pending'
    })
    builder.addCase(getUserPerimetersThunk.rejected, (state) => {
      state.loading = 'failed'
      state.perimeters = []
    })

  },

})

export const { updateSelectedPerimeter, clearPerimeters } = perimeterSlice.actions

export const selectedPerimetersId = (state) => state.perimeters.selectedPerimeters.map(perimeter => perimeter.perimeter)
export const perimetersId = (state) => state.perimeters.perimeters.map(perimeter => perimeter.perimeter)
export const perimeters = (state) => state.perimeters.perimeters
export const selectedPerimeters = (state) => state.perimeters.selectedPerimeters
export const perimetersLoading = (state) => state.perimeters.loading


export default perimeterSlice.reducer


