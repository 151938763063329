import React, { useEffect } from "react";
import "./header.scss";
import { DataGrid, DropDownBox, TextBox } from "devextreme-react";
import { useSelector } from "react-redux";
import { perimeters} from "../../utils/redux/perimeterSlice";
import { FilterRow, Selection, Scrolling, Column } from "devextreme-react/cjs/data-grid";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedPerimeter } from "../../utils/redux/perimeterSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { selectedPerimeters } from "../../utils/redux/perimeterSlice";
const PerimeterSelector = () => {
  const dispatch = useDispatch();
  const perimetersArray = useSelector(perimeters);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("perimeters");
  const perimetersallowed = useSelector(perimeters)
  const perimetersSelected = useSelector(selectedPerimeters)
  const [selectedKeys, setSelectedKeys] = React.useState([...perimetersSelected]);


  const updateSelectedPerimeters = useCallback(
    (e) => {
      dispatch(updateSelectedPerimeter(e.selectedRowKeys));
      setSelectedKeys(e.selectedRowKeys)
    },
    [dispatch]
  );

  const dataGridRender = useCallback(
    () => (
      <DataGrid
        height={345}
        dataSource={perimetersArray}
        colo
        hoverStateEnabled={true}
        selectedRowKeys={selectedKeys}
        onSelectionChanged={updateSelectedPerimeters}
      >
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <FilterRow visible={true} />
        <Column dataField="name" caption="Perimeters" alignment="center"/>
      </DataGrid>
    ),
    [perimetersArray, selectedKeys, updateSelectedPerimeters],
  );


  const displayValue = () => {
    if (!selectedKeys.length) return "Select perimeter(s)";
  
    const fieldRender = selectedKeys
      .map((item) => item.name);
  
    return fieldRender.join(", ");
  }
  
  useEffect(() => {
    if(param){
      const perimeters = param.split(",").map(perimeter => parseInt(perimeter));
      const filteredPerimeters = perimetersallowed
        ?.filter(perimeter => perimeters.includes(perimeter.perimeter))
        .map(perimeter => ({ ...perimeter }));


      if (filteredPerimeters?.length === 0) {
        setSelectedKeys([]);
        dispatch(updateSelectedPerimeter([]));
      }else{
        setSelectedKeys(filteredPerimeters);
        dispatch(updateSelectedPerimeter(filteredPerimeters));
      }
    } 
  }, [param, perimetersallowed, dispatch]);


  return (
        <DropDownBox
        dataSource={perimetersArray}
        defaultValue={perimetersArray}
        value={selectedKeys}
        className="dropdown"
        fieldRender={() => (
          <TextBox readOnly={true} value={displayValue()}  className="display-value"/>
        )}
        valueExpr="perimeter"
        width={400}
        contentRender={dataGridRender}  
        />
  );
};
export default PerimeterSelector;
