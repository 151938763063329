import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./user-panel.scss";
import { getFirstLetters } from "../../utils/utils";
import { logout } from "../../utils/redux/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userName } from "../../utils/redux/userSlice";
import { useLogin } from "../../cookies";
import { clearPerimeters } from "../../utils/redux/perimeterSlice";
export default function UserPanel({ menuMode }) {
  const dispatch = useDispatch();
  const user = useSelector(userName)
  const {removeToken} = useLogin()

  const menuItems = useMemo(
    () => [
      {
        text: "Logout",
        icon: "runner",
        onClick: async ()=> {
          dispatch(logout()); 
          dispatch(clearPerimeters())
          removeToken()

        },
      },
    ],
    [dispatch, removeToken]
  );

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div className={"user-image"}>
            {getFirstLetters(user.firstName, user.lastName)}
          </div>
        </div>
        <div className={"user-name"}>
          {`${user.firstName} ${user.lastName}`}
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}

UserPanel.propTypes = {
  menuMode: PropTypes.string.isRequired,
};
