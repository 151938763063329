import React, {
  useState,
  useEffect,
  useCallback
} from "react"
import { useHistory } from "react-router"
import { fetchLocalBuyerPointsOfSale } from "../../api/localBuyer"
import {
  Spinner,
  Table
} from "../../components"
import PointsOfSaleIcon from "../../resources/icons/shop.svg"
import "./pointsOfSale.scss"
import { useSelector } from "react-redux"
import { user as selectedUser} from "../../utils/redux/userSlice"
import { useLogin } from "../../cookies"

const columns = [
  { key: "name", label: "Name", visible: true },
  { key: "description", label: "Description", visible: true },
  { key: "gpsCoordinates", label: "GPS coordinates", visible: false },
]

const crudOptions = {
  create: false,
  edit: true,
  editInNewTab: true,
  delete: false,
  sendSms: false
}

const PointsOfSale = () => {
  const {token} = useLogin()
  const user = useSelector(selectedUser)
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [pointsOfSale, setPointsOfSale] = useState([])
  const [message, setMessage] = useState('')

  const fetchPointsOfSale = useCallback(async () => {
    const res = await fetchLocalBuyerPointsOfSale(token, user.userId)
    res.isOk ?
      setPointsOfSale(res.data) : setMessage('No points of sale')

    setLoading(false)
  }, [token, user])

  useEffect(() => {
      fetchPointsOfSale()
  }, [fetchPointsOfSale])

  const handleEditClick = (props) =>
    history.push(`/prices/${props.data.pointOfSaleId}`)

  return (
    <>
      <div>
        <div className={"contentBlock"}>
          <img src={PointsOfSaleIcon} alt="pointsOfSaleIcon" />
          <h2>Points of sale</h2>
        </div>

        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>

          { loading ?
            <Spinner />
            :
              message !== "" ?
              <div>{ message }</div>
              :
              <Table
                loading={loading}
                data={pointsOfSale}
                keyExpr={'id'}
                hasSelection={false}
                hasEditing={true}
                hasFilterRow={true}
                hasColumnChoose={true}
                columns={columns}
                crudOptions={crudOptions}
                basePath={'pointsOfSale'}
                handleEditClick={handleEditClick}
              />
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default PointsOfSale
