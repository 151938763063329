import React, { useCallback, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { useProgram } from '../../programs'
import { getForgotPasswordPath } from '../../app-routes';
import "./login-form.scss";
import { loginThunk } from '../../utils/redux/userSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userLoading } from '../../utils/redux/userSlice';
import { userSuccess } from '../../utils/redux/userSlice';
import { useLogin } from '../../cookies';
import { addUser } from '../../utils/redux/userSlice';


export default function LoginForm() {
  const program = useProgram();
  const formData = useRef({});
  const dispatch= useDispatch();
  const loading = useSelector(userLoading)
  const success = useSelector(userSuccess)
  const {setToken} = useLogin()


  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { login, password } = formData.current;
      const result = await dispatch(loginThunk({ programId: program.id, login, password }))
      if (loginThunk.fulfilled.match(result)) {
        const token = result.payload.data?.token
        if (token) {
          setToken(token) 
        }
    
        const cleanedPayload = { ...result.payload.data }
        
        delete cleanedPayload.token

        dispatch(addUser({
          user: cleanedPayload,
          loading: false,
          success: { isOk: true, message: null }
        }))
      }
    },
    [program, dispatch, setToken]
  );

  useEffect(() => {
    if(!success.isOk && success.message){
      notify(success.message, "error", 2000);
    }
  }, [success])

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"login"}
          editorType={"dxTextBox"}
          editorOptions={loginEditorOptions}
        >
          <RequiredRule message="Login is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <Link to={getForgotPasswordPath(program.name)}>Forgot password</Link>
    </form>
  );
}

const loginEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Login",
  mode: "text",
};
const passwordEditorOptions = {
  stylingMode: "outlined",
  placeholder: "Password",
  mode: "password",
};
