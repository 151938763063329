import axios from "axios";
import { getLogoutPath } from '../app-routes'

function withBearerToken(token){
  return token && { headers: { Authorization: `Bearer ${token}`} };
}

function handleError(error){
  const program = window.location.pathname.slice(1).split('/')[0]
  if(error?.response?.status === 401){
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location = getLogoutPath(program)
  }
  return {
    isOk: false,
    message: error.message,
  };
}

export async function getService(token, url) {
  try {
    const res = await axios.get(url, withBearerToken(token));
    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return handleError(error);
  }
}

export async function putService(token, url, values) {
  try {
    const res = await axios.put(url, values, withBearerToken(token));
    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return handleError(error);
  }
}

export async function postService(token, url, values) {
  try {
    const res = await axios.post(url, values, withBearerToken(token));
    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return handleError(error);
  }
}

export async function deleteService(token, url) {
  try {
    const res = await axios.delete(url, withBearerToken(token));
    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return handleError(error);
  }
}
