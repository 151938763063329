import React, { useRef, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  SearchPanel,
  Selection,
  Button as GridButton,
} from "devextreme-react/data-grid"
import { confirm } from "devextreme/ui/dialog"
import { useScreenSize } from "../../utils/media-query";
import { useSelector } from "react-redux";
import { selectedPerimetersId } from "../../utils/redux/perimeterSlice";

const Table = (props) => {
  const history = useHistory()
  const { 
    loading,
    data,
    keyExpr,
    hasEditing,
    hasFilterRow,
    hasColumnChoose,
    columns,
    crudOptions,
    basePath,
    handleSendUrl,
    handleEditClick,
    handleSelectionChange,
    handleDelete,
    renderCell,
    contentReady
  } = props
  
  const datagridRef = useRef(null)
  const { isSmall, isXSmall, isLarge, isXLarge, isMedium} = useScreenSize();

  const perimetersSelected  = useSelector(selectedPerimetersId)

  const renderActionButton = () => {
    return(
      <Column type="buttons" width={isSmall || isXSmall ? 200 : 130} showInColumnChooser={false}>
      {
        crudOptions.sendSms &&
        <GridButton
          hint="Send Participant URL"
          icon="email"
          cssClass={isXSmall ||isSmall ? "list-button-small" : ""}
          onClick={(e) => handleSendUrl(e)}
        />
      }
      { 
        crudOptions.edit &&
        <GridButton 
          name="edit" 
          onClick={(e) => history.push(`${basePath}/${e.row.data.id ?? e.row.data.userId}`, { 
            id: e.row.data.id ?? e.row.data.userId,
            name: e.row.data.name ?? null
          })}
          cssClass={isXSmall ||isSmall ? "list-button-small" : ""}
        />
      }
      {
        crudOptions.editInNewTab &&
        <GridButton
          text="Edit in a new tab"
          icon="plus"
          cssClass={isXSmall ||isSmall || isMedium ? "list-button-small" : ""}

          onClick={(e) => openInNewTab(e.row.data)}
        />
      }
      {
        crudOptions.delete &&
        <GridButton
          text="Delete"
          cssClass={isXSmall ||isSmall || isMedium ? "list-button-small" : ""}
          icon="trash"
          onClick={(e) => confirmDelete(e.row)}
        />
      }
    </Column>
    )
  }
  const setUrlParams = (rowData) => {
    switch (basePath) {
      case 'pointsOfSell':
        return `?name=${rowData.name}`
      default:
        return null
    }
  }

  const openInNewTab = (rowData) => {
    const id = rowData.id ?? rowData.userId,
          urlParams = setUrlParams(rowData),
          perimeterParams = perimetersSelected.length > 0 ? `?perimeters=${perimetersSelected}` : null,
          url = `${basePath}/${id}${urlParams ?? ''}${perimeterParams ?? ''}`,
          newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) newWindow.opener = null
  }

  const showLoader = useCallback(() => {
    loading 
    ?
      datagridRef.current.instance.beginCustomLoading()
    :
      datagridRef.current.instance.endCustomLoading()
  }, [loading])

  useEffect(() => {
    showLoader()
  }, [loading, showLoader])

  const confirmDelete = async (rowData) => {
    const result = await confirm(
     "Are you sure you want to delete this row ?",
     "Delete"
    )
    if(result) {
      handleDelete(rowData)
    }
  }

  return (
    <DataGrid
      id="gridContainer"
      ref={datagridRef}
      dataSource={data}
      keyExpr={keyExpr}
      onContentReady={contentReady}
      onSelectionChanged={handleSelectionChange}
      onEditingStart={handleEditClick}
      showBorders={true}
      showColumnChooser={true}
      showColumnLines={true}
      showRowLines={false}
      columnAutoWidth={true}
      allowColumnResizing={true}
      hoverStateEnabled={true}
    >
      <Selection
        mode="multiple"
        selectAllMode={true}
        showCheckBoxesMode={"always"}
      />

      { hasEditing && <Editing allowUpdating={true} allowDeleting={false} />}

      { hasFilterRow && <FilterRow visible={data.length > 0} />}

      <SearchPanel visible={true} width={240} placeholder="Search..." />

      { hasColumnChoose && 
        <ColumnChooser enabled={true} mode="select" height={columns.length * 60}/>
      }

      {(isSmall || isXSmall) && renderActionButton()}

      { columns.map((column) => 
        column.key === "fullname" ? 
          <Column
            key={column.key}
            dataField={column.key}
            caption={columns.label}
            dataType={column.dataType}
            cellRender={renderCell}
            visible={column.visible}
          />
        : 
        <Column
          key={column.key}
          dataField={column.key}
          caption={column.label}
          dataType={column.dataType}
          visible={column.visible}
        />
      )}

    {(isMedium || isLarge || isXLarge) && renderActionButton()} 
    </DataGrid>
  )
}

export default Table