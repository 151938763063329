import { getService, postService, putService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;



/**
 * Returns farmers info list if success else returns error message
 * @param {string} token
 */
export async function fetchFarmers(token, perimeters) {
  // if perimeters, append to url
  const url = perimeters ? `${baseUrl}/bo/farmers?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/farmers`;
  const fetchedFarmers = await getService(token, url);
  return fetchedFarmers;
}

/**
 * Updates farmer values if success else returns error message
 * @param {string} token
 * @param {string} id
 * @param {object} values
 */
export async function updateFarmer(token, id, values, perimeter) {
  const url = `${baseUrl}/bo/farmers/${id}?perimeters=`+ encodeURIComponent(perimeter);
  const res = await putService(token, url, values);
  return res;
}

/**
 * Creates a farmer if success else returns error message
 * @param {string} token
 * @param {object} values
 */
export async function createFarmer(token, values, perimeter) {
  const url = `${baseUrl}/bo/farmers?perimeters=`+ encodeURIComponent(perimeter);
  const res = await postService(token, url, values);
  return res;
}

/**
 * Returns farmer details with given ID, if success else returns error message
 * @param {string} token
 * @param {string} id
 */
export async function fetchFarmerById(token, id, perimeters) {
  const url = perimeters ? `${baseUrl}/bo/farmers/${id}?perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/farmers/${id}`;
  const fetchedFarmer = await getService(token, url);
  return fetchedFarmer;
}

/**
 * Returns farm data of given farmer id
 * @param {string} token
 * @param {string} farmerId
 */
export async function fetchFarmDataById(token, farmerId,perimeters) {
  const url = perimeters ? `${baseUrl}/bo/farm/getFarmDataByParticipant?user-id=${farmerId}&perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/farm/getFarmDataByParticipant?user-id=${farmerId}`;
  const fetchedFarmData = await getService(token, url);
  return fetchedFarmData;
}

/**
 * Updates farm data of given farmer id
 * @param {string} token
 * @param {object} values
 */
export async function updateFarmData(token, values, perimeters) {
  const url = `${baseUrl}/bo/farm/putFarmData?perimeters=`+ encodeURIComponent(perimeters);
  const res = await putService(token, url, values);
  return res;
}

/**
 * Creates farm data for a given farmer id
 * @param {string} token
 * @param {object} values
 */
export async function createFarmData(token, values, perimeters) {
  const url = `${baseUrl}/bo/farm/postFarmData?perimeters=`+ encodeURIComponent(perimeters);
  const res = await postService(token, url, values);
  return res;
}

/**
 * Returns production data for given farmer id
 * @param {string} token
 * @param {string} farmerId
 */
export async function fetchProductionDataById(token, farmerId, perimeters) {
  const url =   perimeters ? `${baseUrl}/bo/production/getProductionDataByParticipant?user-id=${farmerId}&perimeters=`+ encodeURIComponent(perimeters) : `${baseUrl}/bo/production/getProductionDataByParticipant?user-id=${farmerId}`;
  const fetchedProductionData = await getService(token, url);
  return fetchedProductionData;
}

/**
 * Updates production data of given farmer id
 * @param {string} token
 * @param {object} values
 */
export async function updateProductionData(token, values, perimeters) {
  const url = `${baseUrl}/bo/production/putProductionData?perimeters=`+ encodeURIComponent(perimeters);;
  const res = await putService(token, url, values);
  return res;
}

/**
 * Creates production data for a given farmer id
 * @param {string} token
 * @param {object} values
 */
export async function createProductionData(token, values, perimeters) {
  const url = `${baseUrl}/bo/production/postProductionData?perimeters=`+ encodeURIComponent(perimeters);;
  const res = await postService(token, url, values);
  return res;
}

export async function sendParticipantUrl(token, values) {
  const url = `${baseUrl}/bo/sms/sendGeneratedUrl`;
  const res = await postService(token, url, values);
  return res;
}
