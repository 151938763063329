import { Accordion } from "devextreme-react";
import { useCallback, useEffect, useState, useRef } from "react";
import { getCourseStructure } from "../../api/courses";

import notify from "devextreme/ui/notify";
import { useHistory } from "react-router";
import {Button} from "../../components";
import "./cohort.scss";
import { getCohortsPath } from '../../app-routes'
import { useProgram } from '../../programs'
import { Spinner } from "../../components";
import {ChapterGrid} from "./ChapterGrid.js";
import { useSelector } from "react-redux";
import { selectedPerimetersId } from "../../utils/redux/perimeterSlice.js";
import { useLogin } from "../../cookies/index.js";
//this page handle the distribution of courses
const CoursesDetails = (props) => {

  const {name, courses, fetchCohortData} = props
  const program = useProgram();
  const [selectedItems, setSelectedItems] = useState();
  const [selectedCourses, setSelectedCourses] = useState();
  const isMounted = useRef(false)
  const selectedPerimeters = useSelector(selectedPerimetersId);
  const {token} = useLogin()
  const history = useHistory();

  const fetchCourseDetails = useCallback(
    async (course) => {
      const res = await getCourseStructure(token, course.slug, program.id);
      if (res.isOk) {
        const { chapters } = res.data;
        const chaptersWithFilter = chapters.map((chapter) => {
          if (course.allowedChapters) {
            if (course.allowedChapters.includes(chapter.id.toString())) {
              return { ...chapter, selected: true };
            } else {
              return { ...chapter, selected: false };
            }
          } else {
            return { ...chapter, selected: false };
          }
        });
        if (course.allowedChapters) {
          return {
            ...res.data,
            completed: course.completed,
            started: course.started,
            sessionHours: course.sessionHours,
            chapters: chaptersWithFilter,
            allowedChapters: course.allowedChapters,
          };
        } else {
          return {
            ...res.data,
            completed: course.completed,
            started: course.started,
            sessionHours: course.sessionHours,
            chapters: chaptersWithFilter,
            allowedChapters: [],
          };
        }
      } else{
        notify(
          `Failed to fetch details for course ${course.slug}`,
          "error",
          2000
        );}
    },
    [token, program.id]
  );

  const fetchCohort = useCallback(async () => {
      const coursesDetails = await Promise.all(
        courses.map((item) => fetchCourseDetails(item))
      )

      // filter out undefined values / courses not found by wordpress
      const courseDetailsFilter = coursesDetails.filter((course) => course !== undefined)

      setSelectedCourses(courseDetailsFilter);
      setSelectedItems(courseDetailsFilter);

  }, [ fetchCourseDetails, courses]);

  useEffect(() => {
    if(!isMounted.current) {
        fetchCohort();
        isMounted.current = true
      }
  }, [fetchCohort, isMounted, selectedPerimeters]);

  const handleCancel = () => {
    history.push(getCohortsPath(program.name));
  };


  const CustomTitle = (data) => {
    const renderAvatar = () => {
      return data.imageUrl ? (
        <div
          style={{
            background: `url(${data.imageUrl}) no-repeat #fff`,
            backgroundSize: "cover",
          }}
          className={"user-image"}
        />
      ) : (
        <div className={"text-image"}></div>
      );
    };


    return (
      <div className={"userinfo"}>
        <div className={"image-container"}>{renderAvatar()}</div>
        <div>{data.title}</div>

      </div>
    );
  };

  const selectionChanged = (e) => {
    let newItems = [...selectedItems];
    e.removedItems.forEach((item) => {
      let index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  return (
    <div>
      <h5>Courses for cohort {name}</h5>
      {!isMounted.current ? <Spinner /> :       
      <Accordion
        dataSource={selectedCourses || []}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        selectedItems={selectedItems || []}
        onSelectionChanged={selectionChanged}
        itemTitleRender={CustomTitle}
        itemRender={(data) => (
          <ChapterGrid data={data} fetchCohortData={fetchCohortData}/>
        )}
      />}

      <Button
        text="Back to cohort list"
        onClick={handleCancel}
        buttonStyle={"secondary"}
      />
    </div>
  );
};

export default CoursesDetails;
